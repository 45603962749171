<template>
    <div class="container" style="background-color: #fff;">
        <h1>{{ $store.state.language.layout.bread_crumbs.as }}</h1>
        <div class="row">
            <div class="col-12">
                <a
                    :href="`tel:${$store.state.language.layout.foot.tel}`"
                    class="btn btn-success"
                >
                    {{ $store.state.language.inquiry.as.phonecall }}
                </a>
                {{ $store.state.language.inquiry.as.leavemessage }}
            </div>
            <div
                class="col-12"
                :class="check_form && formData.problem == '' ? 'invalid' : ''"
            >
                <label for="fr_problem"
                    ><span class="red">*</span
                    >{{ $store.state.language.inquiry.as.problem }}</label
                >
                <textarea
                    class="form-control"
                    id="fr_problem"
                    v-model="formData.problem"
                ></textarea>
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div
                class="col-lg-6"
                :class="check_form && formData.customer == '' ? 'invalid' : ''"
            >
                <label for="fr_customer">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.as.customer }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_customer"
                    readonly
                    v-model="formData.customer"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div
                class="col-lg-6"
                :class="check_form && formData.machine == '' ? 'invalid' : ''"
            >
                <label for="fr_machine">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.as.machine }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_machine"
                    readonly
                    v-model="formData.machine"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div
                class="col-lg-6"
                :class="check_form && formData.tel == '' ? 'invalid' : ''"
            >
                <label for="fr_tel"
                    ><span class="red">*</span
                    >{{ $store.state.language.inquiry.tel }}</label
                >
                <input
                    type="text"
                    class="form-control"
                    id="fr_tel"
                    v-model="formData.tel"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div
                class="col-lg-6"
                :class="check_form && formData.contact == '' ? 'invalid' : ''"
            >
                <label for="fr_contact">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.contact }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_contact"
                    v-model="formData.contact"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div class="col-lg-6">
                <label for="fr_contact_title">{{
                    $store.state.language.inquiry.contact_title
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_contact_title"
                    v-model="formData.contact_title"
                />
            </div>
            <div
                class="col-lg-6"
                :class="
                    check_form &&
                    (formData.contact_email == '' || valiad_mail == null)
                        ? 'invalid'
                        : ''
                "
            >
                <label for="fr_contact_email">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.contact_email }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_contact_email"
                    v-model="formData.contact_email"
                />
                <span class="invalid_info">
                    {{
                        formData.contact_email == ""
                            ? $store.state.language.inquiry.require_field
                            : $store.state.language.inquiry.email_format_invalid
                    }}
                </span>
            </div>

            <div class="col-12 text-center mt-2 mb-5">
                <button
                    class="btn btn-primary btn-lg"
                    v-html="$store.state.language.inquiry.submit"
                    @click="send_inquiry_form()"
                ></button>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.red {
    color: #f00;
}

.invalid_info,
.valid_info {
    display: none;
}

.invalid {
    input,
    textarea {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .invalid_info {
        color: #dc3545;
        display: block;
    }
}
</style>
<script>
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
    siteKey: "6LfHnYAeAAAAAG763J4o-g4G3BntOmYdW4--CzYm",
    loaderOptions: {
        useRecaptchaNet: true
    }
});

export default {
    data() {
        return {
            formData: {
                source: "",
                problem: "",
                customer: "",
                machine: "",
                tel: "",
                contact: "",
                contact_title: "",
                contact_email: ""
            },
            token: "",
            check_form: false
        };
    },
    mounted() {
        this.recaptcha();
        setInterval(this.recaptcha, 110000);
        this.formData.source = this.$store.state.lan;
        this.formData.customer = this.$route.params.customer || "";
        this.formData.machine = this.$route.params.machine || "";
    },
    computed: {
        valiad_form() {
            let output = true;
            switch (true) {
                case this.token == "":
                case this.formData.problem == "":
                case this.formData.customer == "":
                case this.formData.machine == "":
                case this.formData.tel == "":
                case this.formData.contact == "":
                case this.formData.contact_email == "":
                case this.valiad_mail == null:
                    output = false;
                    break;
            }
            return output;
        },
        valiad_mail() {
            return String(this.formData.contact_email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        }
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded();
            // Execute reCAPTCHA with action "login".
            this.token = await this.$recaptcha("login");
        },
        send_inquiry_form() {
            let vue = this;
            vue.check_form = true;
            if (vue.valiad_form) {
                const data = new FormData();
                for (let k in vue.formData) {
                    switch (k) {
                        default:
                            data.append(k, vue.formData[k]);
                            break;
                    }
                }
                data.append("token", vue.token);
                vue.$swal.fire({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    html: `
                        <div style="height:5em">
                            <i class="fas fa-circle-notch fa-spin fa-4x" style="color: #42a5f5;"></i>
                        </div>`,
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: false,
                    title: "處理中",
                    width: "12rem",
                    didOpen() {
                        vue.axios
                            .post(`/inquiry_save/as.php`, data)
                            .then(res => {
                                if (res.data.status != "ok") {
                                    console.log(res);
                                    vue.$swal.fire({
                                        icon: "error",
                                        title: res.data.msg || "Error!"
                                    });
                                    vue.recaptcha();
                                } else {
                                    // 清空
                                    document.cookie =
                                        "inquiry=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
                                    vue.$swal
                                        .fire({
                                            icon: "success",
                                            title:
                                                vue.$store.state.language
                                                    .inquiry.finish,
                                            allowEscapeKey: false,
                                            allowOutsideClick: false,
                                            showConfirmButton: true
                                        })
                                        .then(() => {
                                            location.reload();
                                        });
                                }
                            });
                    }
                });
            }
        }
    }
};
</script>
